import { Observable } from 'rxjs';
import {
  AuditPageQueryParamsDto,
  AuditPageDto,
  AuditShowDto,
} from '../dtos/audit.dto';

export abstract class AuditRepository {
  abstract getPage(params: AuditPageQueryParamsDto): Observable<AuditPageDto>;
  abstract show(auditId: number): Observable<AuditShowDto>;
}
